<template>
  <el-card class="iot-networkMode iot-search-header public-page-style">
    <div slot="header" class="clearfix">
      <el-form :inline="true" :model="getForm">
        <el-form-item>
          <el-input
            size="small"
            clearable
            v-model="getForm.networkMode"
            placeholder="请输入查询联网方式名称"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" plain @click="getList()"
            >查询</el-button
          >
        </el-form-item>
        <el-button
          type="primary"
          size="mini"
          class="iot-networkMode-create"
          @click="addNetworkMode()"
          >创建联网方式</el-button
        >
      </el-form>
    </div>
    <div style="height:678px;overflow-y: auto;">

    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
    >
      <el-table-column
        prop="networkMode"
        label="联网方式名称"
      ></el-table-column>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            plain
            @click="editNetworkMode(scope.row)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            plain
            @click="delNetworkMode(scope.row.networkModeId)"
            >删除</el-button
          >
        </template>
      </el-table-column>
      
    </el-table>
    </div>

   <div class="page">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
        :total="total"
        :page-size="getForm.size"
        layout="total, sizes, prev, pager, next, jumper"
        @prev-click="
          () => {
            $set(getForm, 'current', getForm.current - 1);
            getList();
          }
        "
        @next-click="
          () => {
            $set(getForm, 'current', getForm.current + 1);
            getList();
          }
        "
      >
      </el-pagination>
    </div>
    <el-dialog
    top="3%"
      title="填写表单"
      :show-close="false"
      :visible.sync="dialogVisinbile"
      :destroy-on-close="true"
      :close-on-press-escape="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="networkModeForm"
        ref="subRef"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="联网方式名称" prop="networkMode">
          <el-input
            v-model="networkModeForm.networkMode"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
         <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="dialogVisinbile = false">取 消</el-button>
       
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
import { OSS_URL, PLAN_IMG } from "../util/constant";
import reg from "../util/regular";
let that;
export default {
  name: "CreateNetworkMode",

  data() {
    return {
      constant: { OSS_URL, PLAN_IMG },
      theme: { TABLE_HEADER_COLOR },
      dialogVisinbile: false,
      tableData: [],
      getForm: {
        size: 10,
        current: 1,
        networkMode: "",
      },
      total: 0,
      networkModeForm: {
        networkMode: "",
      },
      isCreate: true,
      currentStep: 0,
      currentPlanId: "",
      rules: {
        networkMode: [
          { required: true, message: "请输入联网方式名称", trigger: "blur" },
          {
            pattern: reg.nameR,
            message: "长度为0-15个字符",
          },
        ],
      },
    };
  },
  methods: {
    handleSizeChange(val) {
        that.getForm.size=val;
        that.getList();
      },
      handleCurrentChange(val) {
        that.getForm.current=val;
        that.getList();
      },
    getList: function () {
      that.$api.networkMode.networkModeList(that.getForm).then((response) => {
        if (response.code == 200) {
          that.tableData = response.data.records;
          that.total = response.data.total;
        }
      });
    },
    changePage: function (current) {
      that.$set(that.getForm, "current", current);
      that.getList();
    },
    addNetworkMode: function () {
      that.dialogVisinbile = true;
      that.isCreate = true;
    },
    editNetworkMode: function (obj) {
      that.networkModeForm = obj;
      that.dialogVisinbile = true;
      that.isCreate = false;
    },
    delNetworkMode: function (id) {
      that
        .$confirm("确认删除", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.$api.networkMode.delNetworkMode(id).then((response) => {
            if (response.code == 200) {
              that.getList();
              that.$message({
                type: "success",
                message: "删除成功",
              });
            }
          });
        })
        .catch(() => {});
    },
    submitForm: function () {
      that.$refs["subRef"].validate((valid) => {
        if (valid) {
          let _api = that.isCreate
            ? that.$api.networkMode.addNetworkMode(that.networkModeForm)
            : that.$api.networkMode.editNetworkMode(that.networkModeForm);

          _api.then((response) => {
            if (response.code == 200) {
              that.dialogVisinbile = false;
              that.isCreate = true;
              that.$refs["subRef"].resetFields();
              that.getList();
              that.$message({
                type: "success",
                message: response.msg,
              });
            }
          });
        }
      });
    },
  },
  beforeCreate: function () {
    that = this;
  },
  mounted: function () {
    that.getList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-select,
.el-input,
.el-input-number {
  width: 250px;
}
.el-input-number >>> input {
  text-align: left;
}
.iot-networkMode-create {
  float: right;
  margin-top: 10px;
}
.iot-networkMode-permission {
  height: 387px;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}
.iot-networkMode >>> .iot-step-body {
  width: 100%;
  height: calc(100% - 40px);
  padding: 30px;
}

.iot-networkMode >>> .el-dialog__body {
  width: 95%;
  height: 90%;
  overflow: hidden;
}
.page{
  display: flex;
  justify-content: flex-end;
}
</style>
